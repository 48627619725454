import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import liff from "@line/liff";
import axios from "axios";
import qs from "qs";

const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
    const [userLine, setUserLine] = useState({});
    const [lineAccessToken, setLineAccessToken] = useState(null);
    const [backendTokens, setBackendTokens] = useState({
        accessToken: null,
        refreshToken: null,
    });
    const [verified, setVerified] = useState(null);
    const [isInLineClient, setIsInLineClient] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        const initializeLiff = async () => {
            setIsAuthenticating(true);

            await liff.init({ liffId: process?.env?.NEXT_PUBLIC_LIFF_ID });

            if (!liff.isLoggedIn()) {
                liff.login();
                return;
            }

            const profile = await liff.getProfile();
            setUserLine(profile);

            const lineToken = liff.getAccessToken();
            setLineAccessToken(lineToken);

            setIsInLineClient(liff.isInClient());

            const transaction = Sentry.startTransaction({
                name: "Post: Line Login",
            });

            try {
                const data = qs.stringify({
                    line_access_token: lineToken,
                });

                const config = {
                    method: "post",
                    url: `${process?.env?.NEXT_PUBLIC_BACKEND_URL}/auth/line-login`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: data,
                };

                Sentry.configureScope((scope) => {
                    scope.setSpan(transaction);
                });

                const response = await axios.request(config);

                if (response.data.success) {
                    transaction.setStatus("ok");

                    setBackendTokens({
                        accessToken: response.data.data.access_token,
                        refreshToken: response.data.data.refresh_token,
                    });

                    setVerified(response.data.data.verified);
                } else {
                    transaction.setStatus("unknown_error");

                    const sentryError = new Error("Post: Line Login Failed");
                    sentryError.cause =
                        response?.data?.error || response?.data?.message;

                    Sentry.captureException(sentryError);
                }
            } catch (error) {
                transaction.setStatus("unknown_error");

                const sentryError = new Error("Post: Line Login Failed");
                sentryError.cause =
                    error?.data?.error ||
                    error?.data?.message ||
                    error?.response?.data?.error ||
                    error?.response?.data?.message ||
                    error;

                Sentry.captureException(sentryError);

                console.error("An error occurred:", error);
            } finally {
                transaction.finish();

                setIsAuthenticating(false);
            }
        };

        initializeLiff();
    }, []);

    return (
        <UserContext.Provider
            value={{
                userLine,
                lineAccessToken,
                backendTokens,
                verified,
                isInLineClient,
                isAuthenticating,
            }}
        >
            {children} {process.env.LINE_LIFF}
        </UserContext.Provider>
    );
};

export default UserContext;
