export const generateRegExp = (apiUrl = "") => {
    if (apiUrl == "") {
        return undefined;
    }

    // Escape special characters in the API URL
    const escapedApiUrl = apiUrl.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Create the RegExp pattern
    const regExpPattern = `^${escapedApiUrl}`;

    // Return the RegExp object
    return new RegExp(regExpPattern);
};

export default generateRegExp;
