var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4317703"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { generateRegExp } from "./utils/regExp";

Sentry.init({
    dsn: process?.env?.NEXT_PUBLIC_SENTRY_DSN ?? "",
    release: process?.env?.NEXT_PUBLIC_GIT_SHA ?? "",

    beforeSend(event, hint) {
        const error = hint.originalException;

        if (error && error.isAxiosError && error.response) {
            // Drop the event if it's an AxiosError with status code 404
            return null;
        }

        return event;
    },

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0, // Set profiling sampling rate.

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: process?.env?.NEXT_PUBLIC_ENVIRONMENT !== "production",

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                window.location.origin,
                process?.env?.NEXT_PUBLIC_BACKEND_URL,
                `${generateRegExp(process?.env?.NEXT_PUBLIC_BACKEND_URL)}`,
            ],
            networkRequestHeaders: ["Cache-Control"],
            networkResponseHeaders: ["Referrer-Policy"],
        }),
        Sentry.browserTracingIntegration({
            beforeStartSpan: (context) => {
                return {
                    ...context,
                    // You could use your UI's routing library to find the matching
                    // route template here. We don't have one right now, so do some basic
                    // parameter replacements.
                    name: location.pathname
                        .replace(
                            /\/service\/request\/[^/]+$/,
                            "/service/request"
                        )
                        .replace(/\/cover\/detail\/[^/]+$/, "/cover/detail"),
                };
            },
            enableInp: true,
        }),
        Sentry.BrowserProfilingIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.debugIntegration(),
        Sentry.dedupeIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.sessionTimingIntegration(),
        Sentry.metrics.metricsAggregatorIntegration(),
    ],
    tracePropagationTargets: [
        process?.env?.NEXT_PUBLIC_BACKEND_API_URL ??
            process?.env?.NEXT_PUBLIC_BACKEND_URL ??
            "",
    ],
    environment:
        process?.env?.NEXT_PUBLIC_ENVIRONMENT ??
        process?.env?.ENVIRONMENT ??
        "",
});
