import "../styles/globals.css";
import { useRouter } from "next/router";

import { Fira_Sans, Prompt } from "next/font/google";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-alice-carousel/lib/scss/alice-carousel.scss";

import { UserProvider } from "../context/userContext";

const firaSans = Fira_Sans({
    weight: ["500", "700", "900"],
    subsets: ["latin"],
    variable: "--font-fira-sans",
});

const prompt = Prompt({
    weight: ["300", "400", "500"],
    subsets: ["thai", "latin"],
    variable: "--font-prompt",
});

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const getLayout = Component.getLayout || ((page) => page);

    const shouldUseUserProvider = (path) => {
        // Exclude paths
        if (path.startsWith("/login") || path.startsWith("/signup")) {
            return false;
        }

        // Exclude dynamic paths
        const patterns = [
            /\/service\/request\/qr-code\/.+/,
            /\/service\/confirm\/.+/,
        ];

        for (const pattern of patterns) {
            if (pattern.test(path)) {
                return false;
            }
        }

        return true;
    };

    return (
        <>
            {shouldUseUserProvider(router.pathname) ? (
                <UserProvider>
                    <main
                        className={`${firaSans.variable} ${prompt.variable} w-full`}
                    >
                        {getLayout(<Component {...pageProps} />, pageProps)}
                    </main>
                </UserProvider>
            ) : (
                <main
                    className={`${firaSans.variable} ${prompt.variable} w-full`}
                >
                    {getLayout(<Component {...pageProps} />, pageProps)}
                </main>
            )}
        </>
    );
}

export default MyApp;
